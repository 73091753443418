import { NormalizedCacheObject } from '@apollo/client';
import {
  ComponentContentRecommendedCategories,
  ComponentContentRentalStations,
  AllZonesFragment,
} from '../@types/codegen/graphql';
import { recommendedProductsCachePrefetch } from './layers/RecommendedProducts/cache-prefetch';
import { rentalStationsCachePrefetch } from './layers/RentalStations/cache-prefetch';

type ZoneCacheResponse = {
  apolloState: NormalizedCacheObject;
  data: Record<string, unknown>;
}

export type ZoneCacheFunc<T> = (zone: T) => Promise<ZoneCacheResponse | undefined>;

const mapper: Record<string, ZoneCacheFunc<ComponentContentRecommendedCategories> | ZoneCacheFunc<ComponentContentRentalStations>> = {
  'ComponentContentRecommendedCategories': recommendedProductsCachePrefetch,
  'ComponentContentRentalStations': rentalStationsCachePrefetch,
};

export const getZoneApiCache = async (zones: AllZonesFragment[]): Promise<ZoneCacheResponse> => {
  const req = await Promise.all(
    zones?.map(async (zone) => {
      if (!zone) {
        console.warn('Zone is undefined');

        return;
      }

      const func = mapper[zone.__typename as string];

      if (!func) {
        return;
      }

      // @ts-ignore
      const cache = await func(zone);

      if (!cache) {
        return;
      }

      return cache;
    }),
  );

  const filtered = req
    .filter((item) => item)
    .map((item) => item as ZoneCacheResponse);

  if (filtered.length === 0) {
    return {apolloState: {ROOT_QUERY: {} }, data: {}};
  }

  const reduced = filtered
    .reduce<ZoneCacheResponse>((acc, cur) => {
      return {
        apolloState: {
          ...acc.apolloState,
          ...cur.apolloState,
          ROOT_QUERY: {
            ...cur.apolloState.ROOT_QUERY,
            ...acc.apolloState.ROOT_QUERY,
          },
        },
        data: {
          ...acc.data,
          ...cur.data,
        },
      };
    }, {apolloState: {ROOT_QUERY: {} }, data: {}});

  return reduced;
};

